.blackBackground{
    min-Height: 1000px;

}



.blackBackground li{
    padding: 8px;
    font-size: 22px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #D0D0D0
}
/* 
.blackBackground h1{
    font-size: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} */

.blackBackground h2{
    margin:0;
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}



.section-helplyCommunity{
    display:none;
    min-Height: 890px;
}


.section-helplyCommunity li{
    padding: 8px;
    font-size: 22px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section-helplyCommunity h1{
    font-size: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section-helplyCommunity h2{
    margin:0;
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section-helplyCommunity h3{
    justify-content: left;
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.news-pic-container{
    border: 5px solid rgb(0, 26, 255);
}

.news-pic-container:hover {
    border: 5px solid rgb(0, 26, 255); /* Add a pink border on hover */
    border-color:  rgb(0, 26, 255); /* Change border color on hover to a vibrant orange */
    box-shadow: 0 0 30px #0004ff; /* Add a playful pinkish shadow on hover */
}

.p-news-description {
    font-size: clamp(19px, 2vw, 22px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify; /* Blocksatz */
    padding-bottom: 100px;
    margin: 0; 
}
.p-news-subtile{
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 3;
    margin: 0; 
}




.interactive-image:hover {
    cursor: pointer;
    box-shadow: #002244 0px 0px 10px 5px; /* Broncos Navy*/
}

.interactive-image:hover + .interactive-image-schlagwort {
    color: #007FFF; /* Azure*/
    font-weight: 400;
}

.interactive-image {
    height: clamp( 80px, 30vw , 270px);
    width: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    text-align: center;
    box-shadow: #002244 0px 0px 2px 1px; /* Broncos Navy*/

  }

  @media screen and (max-width: 600px){
    .interactive-image {
        height: clamp( 80px, 30vw , 270px);
        width: auto;
        background-size: auto 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        text-align: center;
        box-shadow: #002244 0px 0px 2px 1px; /* Broncos Navy*/
    
      }
  }
  
  .interactive-image-overlay {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5); /* Grauer transparenter Hintergrund */
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    transition: background-color 0.5s; /* Übergangseffekt für die Hintergrundfarbe */
    display: flex;
    justify-content: center;
    align-items: center;
}
  

  .interactive-image-text {
    opacity: 1; /* Standardmäßig sichtbar */
    transition: opacity 0.5s; /* Übergangseffekt für die Transparenz */
    color: white;
    font-size: clamp(14px, 4vw, 30px);
    font-weight: 500;
    margin: 0; 
  }
  
  .interactive-image-overlay:hover .interactive-image-text {
    opacity: 0; /* Beim Überfahren ausblenden */

  }


  .interactive-image-overlay:hover {
    background-color: rgba(0, 0, 0, 0); /* Transparenter Hintergrund */
}

.interactive-image-schlagwort{
    font-size: clamp(14px, 4vw, 30px);
    font-weight: 400;
    font-family: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif";
    color: #002244; /* Broncos Navy*/
    padding: 0;
    margin: 0; 
}

/* Backgrounds */
.background-DeepInfosKrameda{

    height: 100%;
    padding: 0;
    padding-bottom: 100px;

    background: 
    linear-gradient(190deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),linear-gradient(170deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),
    linear-gradient(10deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),linear-gradient(-10deg, #3b537a 0%, #5072A7 15%, transparent 15.1%);

}

.background-Banner{
    background: 
    linear-gradient(-350deg, #3b537a 0%, #5072A7 70%, transparent 70.1%),
    linear-gradient(190deg, #3b537a 0%, #5072A7 70%, transparent 70.1%);


}