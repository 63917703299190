.conVideo{
    display: flex;
    justify-content: center;
    height: auto;
    /* max-height: 650px; */
    /* width: 100%; */
    background: linear-gradient(#3b537a 0%, #5072A7 87.5%); 
    background-size: 100%;


}
.responsive-video {
    max-width: 70%;
    height: auto;
    z-index: 10;

  }

@media screen and (max-width: 768px) {
    .responsive-video {
        max-width: 100%;
        height: auto;
        z-index: 10;
    }
    
}