


.navi-bar {
  z-index: 10; /* Adjust as needed */
  display: flex;
  justify-content: center; /* Zentriert den Inhalt */
  position: relative; /* Erforderlich für das transform */
  left: -5px; /* Verschiebt das Element 10 Pixel nach links */
  border: solid 1px grey;
  box-shadow: 0 0 15px rgb(67, 30, 104);
  background-color: transparent;
  max-height: 80px;
  max-width: 1388px;
  padding: 0;
  backdrop-filter: blur(40px);
}


.nav-linker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
}

.nav-linker ul {
    display: flex;
    padding: 0;
    margin: 0;
}

.nav-linker ul li {
    flex: 1;
    display:flex;
    justify-content: center;
    align-items: center;
}

.nav-linker ul li a {
    text-decoration: none;
}


.nav-linker ul li :hover {
    background-color: transparent;
    text-shadow: 0px 8px 10px #5072A7;
}

/*COLUMN*/
.nav-linker-column ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
}

.nav-linker-column ul li {
  flex: 1;
  display:flex;
}

.nav-linker-column ul li a {
  text-decoration: none;
  color: black;
  font-size: clamp(1.0rem, 1.5vw, 1.4rem);
  font-weight: 200;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
}
.nav-linker-column ul li :hover {
  background-color: transparent;
  text-shadow: 0px 8px 10px #5072A7;
}



.ionTitle-menu{
    text-align: center;
}
.header-menuTitel{
    background-color: #000000;
    color:white;
    text-align: center;
}
ion-list.md.list-md{
    padding: 0;
    margin:0;
}
ion-icon {
    font-size: 22px;
    padding: 15px;
    color: #002147;
  }

ion-item:hover {
    color: rgb(0, 0, 0);

    ion-icon {
        color: rgb(0, 0, 0);
    };
    ion-text {
        font-weight: 600
    };
 }
 ion-list-header {
    --background: #202020e8;
    --color: #ffffff;
    font-size: 18px;
    letter-spacing: 2px;

  }
  
  .ionListHeader-name{
    padding-left: 7.5px;
    background-color: #1F305E;

  }

  .signin-signup-button{
    text-decoration: none;
    color: black;
    font-size: clamp(1.0rem, 1.5vw, 1.4rem);
    font-weight: 200;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 2px;
    text-wrap: wrap;
  }
  .signin-signup-button:hover{
    color: rgb(0, 26, 255); /* Textfarbe blau */
    background-color: transparent;
    text-shadow: 0px 5px 20px rgb(0, 26, 255); /* Blaues Leuchten mit Streuung */
    font-weight: 500;
    cursor:pointer
  }

  .menuAccount{
    z-index: 15; /* Adjust as needed */
    position: fixed; 
    height: 100vh;
  }

  .broncoNavy{
    background-color: #002244;
  }
  

 .headerMenue{
  z-index: 150; /* Adjust as needed */
  position: fixed;
  left:0; 
  height: 100vh;
 }

 .burgerMenue{
  display: flex;
  justify-content: end;
  align-items: center;
  height: 95px;
  padding-right: 20px;
 }

 .kramedaLogo {
  transition: opacity 0.3s ease;
}

.kramedaLogo.hidden {
  opacity: 0;
}

.img-shopping-cart{
  filter: invert(10%) sepia(40%) saturate(1670%) hue-rotate(185deg) brightness(97%) contrast(100%);
  transition: all 0.3s ease;
  max-width: 20px;
  min-width: 16px; 
  max-height: 50%;
  cursor: pointer; 
  padding-right: 5px;

}

.img-shopping-cart:hover {
  filter: invert(20%) sepia(60%) saturate(1670%) hue-rotate(165deg) brightness(97%) contrast(100%);

}

.col-shoppingCart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;

}


.p-headerLinks{
  font-size: 16px;
  font-weight: 400;
  color: #07183a;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
}

.p-headerLinks:hover{
  color: #07183a;
  font-weight: 600;
  cursor: pointer;
  text-shadow: 0px 5px 20px rgba(17, 54, 127, 0.404);
}

.p-Kontaktdaten{
  font-size: 16px;
  font-weight: 400;
  color: #07183a;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
}