

.bg-futureOutlook{
    height: 100%;
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 40%),linear-gradient(-165deg, #3b537a 0%, #5072A7 80%, transparent 80%),linear-gradient(140deg, #3b537a 0%, #5072A7 100%, transparent 100%);
    padding: 0;
    margin:0;
    border-bottom: 1px solid #1E2952;

}
.row-grid-vision{
    width: 85%;
    max-width: 1250px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
}

.visionPic {
    width: auto;
    height: auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0 20px 10px rgba(0, 0, 0, 0.5)); /* Ändern Sie den Schatten nach Bedarf */

}

.visionPic2 {
    width: 60%;
    height: auto;
    object-fit: cover;
    position: relative;
    margin-right: -250px; /* Ändern Sie diesen Wert entsprechend Ihrer Anforderung */
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5)); /* Ändern Sie den Schatten nach Bedarf */

}
.splitted-phrase{
    color: #b1d3f4;
    font-size: 22px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
    font-weight: 200;
    letter-spacing: 2px;
    font-style: italic;
    margin: 0;
}

.span-phrase{
    min-width: 200px;
}