.questionMark{
    color:#e0efff;
    max-width: 0px;
    max-height: 0px; 
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    font-weight: 100;
    margin-left: 1px;
}

.grid-questionmark{
    display: flex;
    margin: 0;
    padding:0;
    max-width: fit-content;
    background: linear-gradient(10deg, #003180 0%, #174ca8 45%,  #1861df 80.1%);
    border: #00308f 2px solid;
    border-radius: 100%;
    margin:10px;
}
.grid-questionmark:hover{
    background: linear-gradient(10deg, #1861df 0%, #174ca8 45%, #003180 80.1%);
    border: #1861df 2px solid;
    transform: scale(1.3);
}

.modal-questionmark{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    box-shadow: #003180 0px 0px 10px 0px;
    border: 2px solid #000;
    border-radius: 8px;
    background: linear-gradient(10deg, #003180 0%, #174ca8 45%,  #1861df 80.1%);
    padding: 50px;
}

.modal-title{
    font-size: clamp(1.5rem, 2.5vw, 3rem);
    color: #e3edff;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding:0px;
    margin:0px;

}

.grid-modal-title{
    margin-bottom: 10px;
}
.grid-modal-text{
    padding: 10px;
}
