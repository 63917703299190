  /* Footer */
  .footerStyle{
    background-color: whitesmoke;    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 40px rgba(75, 75, 75, 0.38);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; /* Zentriert den Inhalt vertikal */
    height: 150px;
    width:100%;
    color:black;
  }

  .link{
    padding-left: 50px;
    text-decoration: none;
    color: black;
    font-size: clamp(14px, 2vw, 18px);
    font-weight: 200;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: clamp(1px, 2vw, 5px);
  }
  .link:hover{
    color: rgb(0, 26, 255); /* Textfarbe blau */
    background-color: transparent;
    text-shadow: 0px 5px 20px rgb(0, 26, 255); /* Blaues Leuchten mit Streuung */
    font-weight: 500;
  }
