.image-list-item{
    width: 100%;
    height: auto;
    cursor: pointer;

}
@media screen and (max-width: 950px){
    .image-list-item{
        min-height: 200px;
        width: 100%;
    }
    
}

.image-list-item:hover{
    box-shadow: 0 0 10px #0004ff;
    z-index: 1;
}
.image-list-container{
        width: 60%;
        height: 100%;
        margin: 0 auto;
        background-color: #3b537acc; /* leichter grauer Schimmer */
        border: 5px solid #3b537a; /* dunklerer blauer Rand */
        padding: 20px;
        border-radius: 10px;

    
}
@media screen and (max-width: 950px){
    .image-list-container{
        width: 98%;
    }
    
}

.modal-img{
    width: 100%;
    height: auto;
    max-height: 90vh;
    cursor: pointer;
    min-width:250px;
}