.layout-hilfsleistungenDetails{
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 40.1%),linear-gradient(-165deg, #3b537a 0%, #5072A7 50%, transparent 40%);
}
.layout-hilfsleistungenDetails-space{
    /* background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 40%),linear-gradient(-165deg, #3b537a 0%, #5072A7 70%, transparent 70%); */
}

.hilfsleistungenDetails-img{
    width: 100%;
    max-width: 500px;
    height: 100%;
    object-fit: cover;
    max-height: 600px;
    object-position: center center;
    border: 2px solid #000000;
    border-radius: 10px;
    box-shadow: 0 0 10px #6495ED;
}

.box{
    width: 100%;
    height: 100%;
    /* background: linear-gradient(-130deg, #3b537a 0%, #5072A7 40%, transparent 40%); */
    /* background: linear-gradient(110deg, #3b537a 0%, #5072A7 55%, transparent 55%); */
    background: linear-gradient(110deg, #3b537a 0%, #5072A7 43%, transparent 43.1%);
    border-radius: 10px;
    box-shadow: 0 0 10px #002147; /* #002147 */
    width: 90%;
    margin: 100px;
}

@media screen and (max-width: 1200px){
    .box{
        background: linear-gradient(150deg, #3b537a 0%, #5072A7 35%, transparent 35.1%);
    }
    
}

.big-keyword{
    font-size: clamp( 30px,4vw,50px);

    font-weight: 400;
    text-transform: uppercase;
    color: #F0F8FF;
    text-align: center;
    margin: 0;
}
