:root{
  --primary-max-width: 1400px;
  /* --primary-helply-bgcolor ca. #3b537a und #5072A7 87.5%
  background: linear-gradient(-10deg, #3b537a 0%, #5072A7 87.5%, transparent 88%); */

}

.buttonEffekt a{
    text-decoration: none; /* Entfernt die Unterstreichung */
    color: black;
}
.buttonEffekt {
    z-index: 2;
    background: transparent;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    transition: color 200ms ease;
    color:black;
    font-size: clamp(17px, 2vw, 30px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
}


.buttonEffekt:hover{
    color: white;
}
.buttonEffekt::after{
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: black;
    transition: height 0ms ease;
}
.buttonEffekt:hover::after{
    height: 100%;
    transition: height 200ms ease;
}
.buttonEffekt::before{
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: black;
    transition: background 0ms ease, height 200ms ease;
}
.buttonEffekt:hover::before {
    background: transparent;
    height: 100%;
}



/* Input */
.inputDesign {
    width: 100%;
    background-color: transparent; /* Hintergrundfarbe */
    border: none; /* Kein Rahmen um die Fläche */
    border-bottom: 4px solid rgb(51, 71, 253); /* Unterstreichungseffekt */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 19px; /* Schriftgröße */
    color: #333; /* Schriftfarbe der EINGEGEBENEN Zeichen */
    transition: border-bottom-color 0.3s; /* Animiere die Farbe des Unterstreichungseffekts */
    text-align: left;
    padding: 5px;
}
.inputDesign::placeholder {
    color: #666; /* Schriftfarbe der placeholder */
}

/* Hover-Effekt für Input-Felder */
.inputDesign:hover {
    border-bottom-color: #999; /* Ändert die Farbe des Unterstreichungseffekts beim Hovern */
}

/* Focus-Effekt für Input-Felder */
.inputDesign:focus {
    outline: none; /* Entfernt die Standard-Fokus-Rahmen */
    border-bottom-color: #007bff; /* Ändert die Farbe des Unterstreichungseffekts beim Fokussieren */
}

ion-content {
    --offset-bottom: auto !important; /* Änderung: Automatische Berechnung des Abstands zum unteren Bildschirmrand */
    overflow: auto;
  
    /* Vertikaler Scrollbar */
    &::-webkit-scrollbar {
      scrollbar-width: auto; 
      width: 10px !important;
    }
    
    /* Hintergrundfarbe des Scrollbars */
    &::-webkit-scrollbar-track {
      background: #d8d8d8 !important;
    }
  
    /* Farbe des Scrollbalken */
    &::-webkit-scrollbar-thumb {
      background: black !important;
    }
  
    /* Farbe des Scrollbalken beim Hovern */
    &::-webkit-scrollbar-thumb:hover {
      background: #050047 !important;
    }
  }
  
   .page-design{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    margin: auto; /* Auto-Margin horizontal, 30px oben und unten */
    
    box-shadow: 0px -8px 8px rgb(129, 129, 129), 0px 8px 8px rgb(116, 116, 116);
    max-width: var(--primary-max-width);
    
    /* max-width: 100%; */
  }
  
  
  p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:black;
    font-weight: 200;
    letter-spacing: 2px;
    padding: 10px;
    font-size: 20px;
  }
  
  
  /* BANNER------------------------------------------------------------------- */
  
  .container {
    display: flex;
    width:100%;
    overflow: hidden;
    height: fit-content;
    max-height:200px;
    border-top: 2px solid rgb(0, 26, 255); /* Add a pink border on hover */
    border-bottom: 2px solid rgb(0, 26, 255); /* Add a pink border on hover */
    border-color:  rgb(0, 26, 255); /* Change border color on hover to a vibrant orange */
    box-shadow: 0 0 10px #0004ff; /* Add a playful pinkish shadow on hover */
    z-index: 5;
  }
  
  
  .cooperationsBanner {
    display: flex;
    animation: slide 20s linear infinite ;
    gap: 37.5px;
  }
  
  
  .cooperationsBanner img {
    /* height: 100px; */
  
    height: clamp(60px, 7vw, 100px);
    width: 100%; /* Breite des Containers */
  
    filter: grayscale(100%);
    transition: filter 0.5s;
  }
  
  .cooperationsBanner img:hover {
    filter: grayscale(0%);
    cursor: pointer;
  }
  
  @keyframes slide {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-50%); /* 5 Bilder * 100% (pro Bild) */
    }
    100% {
        transform: translateX(-80%); /* 5 Bilder * 100% (pro Bild) */
    }
  
  }
  
  
  /* Hintergrundfarben------------------------------------------------------------------- */ 
  .bluelineM10degree{

    /* background: black;
    background-image: url('../SVG/Hintergrund/blackWaves.png');
    background-size: 100% 30%;
    background-repeat: no-repeat;
    color:white;
     */

     /* background: linear-gradient(-10deg, rgb(36, 2, 161) 0%, rgb(5, 79, 216) 87.5%, transparent 88%); */
     background: linear-gradient(-10deg, #3b537a 0%, #5072A7 87.5%, transparent 20%);

  }

  .blueline165degree {
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 10.1%);
    color: white;
}


  .blueline165degree h1, .blueline165degree p, .bluelineM10degree h1, .bluelineM10degree p {
    color:white;
  } 
  .doubleblueline{
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 40.1%),linear-gradient(-165deg, #3b537a 0%, #5072A7 40%, transparent 40.1%);
    color: white;
  }

  .doubleblueline-deeper{
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 60%, transparent 10.1%),linear-gradient(-165deg, #3b537a 0%, #5072A7 40%, transparent 10.1%);
    color: white;
  }


  .doubleblueline-whiteMiddle-invert{
    background: linear-gradient(10deg, #3b537a 0%, #5072A7 40%, transparent 10.1%),linear-gradient(-10deg, #3b537a 0%, #5072A7 40%, transparent 10.1%);
  }
  .doubleblueline-leftBlueRightWhite{
    background: linear-gradient(40deg, #3b537a 0%, #5072A7 25%, transparent 10.1%),linear-gradient(130deg, #3b537a 0%, #5072A7 25%, transparent 10.1%);
    /* background: linear-gradient(0deg, #3b537a 0%, #5072A7 40%, transparent 10%),linear-gradient(-165deg, #3b537a 0%, #5072A7 40%, transparent 10%); */
  }

  h1{
    text-transform: uppercase;
    text-align: center;
  
  }
  
  .sloganText{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      color:black;
      font-weight: 200;
      letter-spacing: 2px;
      padding: 10px;
      font-size: 22px;
      text-align: center;
      font-style: italic;
      font-size: clamp(20px, 3vw, 30px);
  }
  

  

  ion-toolbar {
    --background: #002244;
    --color: white;
  }

  ion-button{
    --background: #002244;
    --color: white;
  }

  