.btn-krameda-promo {
    height: 40px;
    font-size: clamp(12px, 2vw, 15px);
    width: clamp(150px, 20vw, 400px);
    word-wrap: break-word;
    white-space: normal;
}

.row-btn-krameda-promo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/*              PromotionKrameda            */
.promotion-krameda {
    background-image: url("../assets/MunichOlympiaTower.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    position: relative;
    box-shadow: 0 10px 30px #1E2952; /* Add a playful pinkish shadow on hover */
    display: "flex";
    display: flex;
    justify-content: center;
    align-items: center;
    height: clamp(550px, 60vw, 800px);
}


.promotion-krameda-bg{
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 100px;
    margin-bottom: 10px;
}
.promotion-krameda-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
}


