
/* SPALTE 1 */
.timeline{
    height: 100%;
    display:flex;
    justify-content: center;
}

/* SPALTE 2 */

.grid-timelineAndVision{
    margin:0;
    min-height: 900px;
    width: 100%;
    background:
    linear-gradient(3deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),
    linear-gradient(185deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),
    linear-gradient(175deg, #3b537a 0%, #5072A7 10%, transparent 10.1%);
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 700px) {
    .grid-timelineAndVision {
      background:
      linear-gradient(18deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),
      linear-gradient(160deg, #3b537a 0%, #5072A7 6%, transparent 6.1%),
      linear-gradient(190deg, #3b537a 0%, #5072A7 5%, transparent 5.1%);    }
  
}
.row-timelineAndVision{
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.col-vision{
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.grid-vision{
    border-radius: 10px;
    box-shadow: 0 0 10px #002147; /* #002147 */
    height: 100%;
    min-height: 100vh;
}


   .point {
    z-index: 10;
    width: clamp(28px, 4vw, 56px);
    height: auto;

} 

.col-textAndPoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Elemente vertikal oben ausrichten */
    text-align: center;
}
  
  .point.pointTwo {
    background-image: url("../assets/LinePoints/2Point.png"); 
  }
  
  .point.pointThree {
    background-image: url("../assets/LinePoints/3Point.png"); 
  }
  
  .point.pointFour {
    background-image: url("../assets/LinePoints/4Point.png"); 
    
  }

  .point:hover {
    transition: cubic-bezier(1, 0, 0, 1) 0.2s; /* Übergangseffekt */
    transform: scale(1.3); /* Vergrößern beim Überfahren mit der Maus */
    cursor: pointer;
    filter: brightness(1.1) hue-rotate(30deg); /* Helligkeit erhöhen und Blauton hinzufügen */
}

  .line {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 83%;
    border-top: 2px solid #002244;
  }
 
  .row-with-line {
    background-image: url("../assets/LinePoints/blackLine.png");
    background-repeat: no-repeat;
    background-position: 45px 81%; /* Positionierung der Linie */
    background-size: contain;
}

@media screen and (max-width: 508px) {
    .row-with-line {
        background-image: none; /* Hintergrundbild entfernen */
    }
}

.grid-history{
  border-radius: 1px;
  box-shadow: 0 0 1px #002147; /* #002147 */
  height: 90%;
  min-height: 500px;
}
.point-text {
  padding: 0;
  margin: 0;
  color: #002147;
  font-size: clamp(12px, 2vw, 17px);
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}



.col-textAndPoint:hover .point-text {
  color: #6688AA;
  cursor:pointer;
}


.col-textAndPoint:hover + .point {
  color: #6688AA;
  cursor:pointer;

  transition: cubic-bezier(1, 0, 0, 1) 1s; /* Übergangseffekt */
  transform: scale(1.3); /* Vergrößern beim Überfahren mit der Maus */
  cursor: pointer;
  filter: brightness(1.1) hue-rotate(30deg); /* Helligkeit erhöhen und Blauton hinzufügen */

}

.grid-img-selectedPoint{
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 400px; */

}
.img-selectedPoint{

  width: 60%;
  height: 80%;
  object-fit: cover;
  border-radius: 10px;
  border: 10px solid #002147;
}


.grid-time-img-selectedPoint{
  display: flex;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 10px #002147; /* #002147 */
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: linear-gradient(165deg, #3b537a 0%, #5072A7 40%, transparent 40.1%),linear-gradient(-165deg, #3b537a 0%, #5072A7 80%, transparent 80.1%),linear-gradient(140deg, #3b537a 0%, #5072A7 100%, transparent 100%);
  margin-top: 10px;
  width: 95%;
}

.promoImg{
  height: 95%;
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid #002147;
}

.grid-description{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  align-items: center;  
  padding: 20px;
}

@media screen and (max-width: 700px) {
  .grid-description {
    padding: 0;
  }
  
}